import {Button, List, Modal, Tabs} from 'antd'
import * as React from 'react'
import {useEffect} from 'react'
import {useQuery, useQueryClient} from 'react-query'
import {renderRoutes, RouteConfigComponentProps} from 'react-router-config'
import {useHistory, useParams} from 'react-router-dom'
import {SERVER_HOST} from '../../../services/api'
import {qs} from '../../queries'

type AttendanceDetailPageProps = RouteConfigComponentProps

export const ClientIncomeDetailPage: React.FC<AttendanceDetailPageProps> = (props) => {
  const { item_id } = useParams<{ item_id: string }>()
  const history = useHistory()
  const queryClient = useQueryClient()

  const cur_item = useQuery(['getTransaction', item_id], () => qs.transactions.client_details(item_id), {
    enabled: false,
    retry: false
  })

  useEffect(() => {
    cur_item.refetch()
  }, [item_id])

  const handleCancel = () => {
    queryClient.removeQueries(['getTransaction', item_id], { inactive: true, stale: true })

    setTimeout(() => {
      history.push('/transactions')
    }, 200)
  }

  return (
    <React.Fragment>
      <Modal
        visible={true}
        maskClosable
        destroyOnClose
        onCancel={handleCancel}
        width={800}
        closable={false}
        className="ant-modal-tabs-only"
        footer={[
          <div key="1" className="attendance-buttons">
            <Button key="4" type="default" onClick={handleCancel}>Fechar</Button>
          </div>
        ]}
      >
        {cur_item.data?.id &&
          <Tabs defaultActiveKey={'1'}>

            <Tabs.TabPane tab="Anexos" key="4">
              <List
                loading={cur_item.isLoading}
                itemLayout="horizontal"
                dataSource={cur_item.data?.attachments ?? []}
                rowKey="id"
                renderItem={item => (
                  <List.Item actions={[<a href={`${SERVER_HOST}${item.image}`} target="_blank">abrir</a>]}>
                    <List.Item.Meta
                      description={item.file_name}
                    />
                  </List.Item>
                )}
              />
            </Tabs.TabPane>
          </Tabs>}
        {renderRoutes(props.route?.routes)}
      </Modal>
    </React.Fragment>
  )
}
