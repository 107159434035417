import {Button, Form, Modal} from 'antd'
import {FormikProvider, useFormik} from 'formik'
import * as React from 'react'
import {useEffect} from 'react'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {RouteConfigComponentProps} from 'react-router-config'
import {useHistory, useParams} from 'react-router-dom'
import {FInput, FSelect} from '../../../components/form/FormInputs'
import {qs} from '../../queries'
import {EvolutionModel} from '../../typings'

type AttendanceEvolutionDetailPageProps = RouteConfigComponentProps
type FormValues = { [K in keyof EvolutionModel]?: EvolutionModel[K] | '' }

export const TIME_SELECT = [
  { value: 15, label: '15 seg' },
  { value: 30, label: '30 seg' },
  { value: 45, label: '45 seg' },
  { value: 60, label: '60 seg' },
  { value: 60 * 2, label: '2 min' },
  { value: 60 * 3, label: '3 min' },
  { value: 60 * 4, label: '4 min' },
  { value: 60 * 5, label: '5 min' },
  { value: 60 * 6, label: '6 min' },
  { value: 60 * 7, label: '7 min' },
  { value: 60 * 8, label: '8 min' },
  { value: 60 * 9, label: '9 min' },
  { value: 60 * 10, label: '10 min' },
  { value: 60 * 15, label: '15 min' },
  { value: 60 * 20, label: '20 min' },
  { value: 60 * 25, label: '25 min' },
  { value: 60 * 30, label: '30 min' },
  { value: 60 * 35, label: '35 min' },
  { value: 60 * 40, label: '40 min' },
  { value: 60 * 45, label: '45 min' },
  { value: 60 * 50, label: '50 min' },
  { value: 60 * 55, label: '55 min' },
  { value: 60 * 60, label: '60 min' }
]

export const KEEP_REST_SELECT = [
  { value: 2, label: '2 seg' },
  { value: 3, label: '3 seg' },
  { value: 5, label: '5 seg' },
  { value: 10, label: '10 seg' },
  { value: 15, label: '15 seg' },
  { value: 20, label: '20 seg' },
  { value: 25, label: '25 seg' },
  { value: 30, label: '30 seg' },
  { value: 45, label: '45 seg' },
  { value: 60, label: '60 seg' },
  { value: 60 * 2, label: '2 min' },
  { value: 60 * 3, label: '3 min' },
  { value: 60 * 4, label: '4 min' },
  { value: 60 * 5, label: '5 min' },
  { value: 60 * 6, label: '6 min' },
  { value: 60 * 7, label: '7 min' },
  { value: 60 * 8, label: '8 min' },
  { value: 60 * 9, label: '9 min' },
  { value: 60 * 10, label: '10 min' },
  { value: 60 * 15, label: '15 min' },
  { value: 60 * 20, label: '20 min' }
]

export const WIGHT_SELECT = [0.5, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(i => ({ value: i, label: i }))

export const ELASTIC_SELECT = [
  { value: 1, label: 'Extra-leve' },
  { value: 2, label: 'Leve' },
  { value: 3, label: 'Médio' },
  { value: 4, label: 'Forte' },
  { value: 5, label: 'Extra-forte' }
]

export const AttendanceEvolutionDetailPage: React.FC<AttendanceEvolutionDetailPageProps> = (props) => {
  const { item_id, attendance_id } = useParams<{ item_id: string, attendance_id: string }>()
  const history = useHistory()
  const queryClient = useQueryClient()

  const IS_TABLE_VIEW = history.location.pathname.indexOf('list') > -1
  const ROOT_PATH = IS_TABLE_VIEW ? '/attendances-list' : '/attendances'

  const interventions = useQuery('getInterventions', () => qs.interventions.all())
  const cur_item = useQuery(['getEvolution', item_id], () => qs.evolutions.get(item_id), {
    enabled: false,
    retry: false
  })
  const create = useMutation(values => qs.evolutions.post(values), {
    onSuccess: () => {
      handleCancel()
    }
  })
  const update = useMutation(values => qs.evolutions.patch(values), {
    onSuccess: () => {
      handleCancel()
    }
  })

  useEffect(() => {
    cur_item.refetch()
  }, [item_id])

  let mutating = create.isLoading || update.isLoading

  const handleCancel = () => {
    queryClient.removeQueries(['getEvolution', item_id], { inactive: true, stale: true })

    setTimeout(() => {
      history.push(`${ROOT_PATH}/${attendance_id}`)
    }, 200)
  }

  const formikBag = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      id: cur_item.data?.id ?? '',

      attendance: attendance_id,
      intervention: cur_item.data?.intervention_id ?? '',

      frequency: cur_item.data?.frequency ?? 0,
      series: cur_item.data?.series ?? 0,
      repeats: cur_item.data?.repeats ?? 0,
      time: cur_item.data?.time ?? 0,
      keep_by: cur_item.data?.keep_by ?? 0,
      rest: cur_item.data?.rest ?? 0,
      weight: cur_item.data?.weight ?? 0,
      elastic: cur_item.data?.elastic ?? 0,
      interval: cur_item.data?.interval ?? 0,
      observations: cur_item.data?.observations ?? ''

    },
    onSubmit: (values: any) => {
      if (cur_item.data?.id) {
        update.mutate(values)
      } else {
        create.mutate(values)
      }
    }
  })

  return (
    <React.Fragment>
      <Modal
        visible={true}
        maskClosable
        destroyOnClose
        onCancel={handleCancel}
        width={500}
        closable={false}
        footer={[
          <Button key="2" type="default" onClick={handleCancel}>Fechar</Button>,
          <Button key="1" type="primary" onClick={formikBag.submitForm} loading={mutating}>Salvar</Button>
        ]}
      >
        {!cur_item.isLoading && !interventions.isLoading &&
          <FormikProvider value={formikBag}>
            <Form layout="vertical">
              <FSelect name="intervention" label="Intervenção" options={interventions.data?.map(i => ({ value: i.id, label: i.name }))}/>

              <FInput name="series" label="Series" type={'number'}/>
              <FInput name="repeats" label="Repetições" type={'number'}/>
              <FSelect name="time" label="Fazer por" options={TIME_SELECT}/>
              <FSelect name="keep_by" label="Manter por" options={KEEP_REST_SELECT}/>
              <FSelect name="rest" label="Descanso" options={KEEP_REST_SELECT}/>
              <FSelect name="weight" label="Carga (em kg)" options={WIGHT_SELECT}/>
              <FSelect name="elastic" label="Elástico" options={ELASTIC_SELECT}/>

              <FInput name="observations" label="Observações"/>
            </Form>
          </FormikProvider>}
      </Modal>
    </React.Fragment>
  )
}
