import * as React from 'react'
import {useEffect} from 'react'
import {Button, Form, Modal} from 'antd'
import {useHistory, useParams} from 'react-router-dom'
import {RouteConfigComponentProps} from 'react-router-config'
import {FormikProvider, useFormik} from 'formik'
import {FDate, FSelect} from '../../../components/form/FormInputs'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {qs} from '../../queries'
import {LoanModel} from '../../typings'
import * as Yup from 'yup'

type LoanDetailPageProps = RouteConfigComponentProps

type FormValues = { [K in keyof LoanModel]?: LoanModel[K] | '' }

export const LoanDetailPage: React.FC<LoanDetailPageProps> = (props) => {
  const fisios = useQuery('getFisios', () => qs.fisios.all())
  const equipments = useQuery('getEquipments', () => qs.equipments.all())
  const patients = useQuery('getPatients', () => qs.patients.all())

  const { item_id } = useParams<{ item_id: string }>()
  const history = useHistory()
  const queryClient = useQueryClient()

  const cur_item = useQuery(['getLoan', item_id], () => qs.loans.get(item_id), {
    enabled: false,
    retry: false
  })

  const create = useMutation(values => qs.loans.post(values), {
    onSuccess: () => handleCancel()
  })
  const update = useMutation(values => qs.loans.patch(values))

  useEffect(() => {
    cur_item.refetch()
  }, [item_id])

  let mutating = create.isLoading || update.isLoading

  const handleCancel = () => {
    queryClient.removeQueries(['getLoan', item_id], { inactive: true, stale: true })

    setTimeout(() => {
      history.push('/loans')
    }, 200)
  }

  const formikBag = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      id: cur_item.data?.id ?? '',
      equipment: cur_item.data?.equipment_id ?? '',
      fisio: cur_item.data?.fisio_id ?? '',
      patient: cur_item.data?.patient_id ?? '',
      retired_date: cur_item.data?.retired_date ?? '',
      returned_date: cur_item.data?.returned_date ?? ''
    },
    validationSchema: Yup.object().shape({
      equipment: Yup.string().required(),
      retired_date: Yup.string().required()
    }),
    onSubmit: (values: any) => {
      if (cur_item.data?.id) {
        update.mutate(values)
      } else {
        create.mutate(values)
      }
    }
  })

  return (
    <React.Fragment>
      <Modal
        visible={true}
        maskClosable
        destroyOnClose
        onCancel={handleCancel}
        width={400}
        closable={false}
        footer={[
          <Button key="2" type="default" onClick={handleCancel}>Fechar</Button>,
          <Button key="1" type="primary" onClick={formikBag.submitForm} loading={mutating}>Salvar</Button>
        ]}
      >
        {!cur_item.isLoading && !equipments.isLoading && !fisios.isLoading && !patients.isLoading &&
        <FormikProvider value={formikBag}>
          <Form layout="vertical">
            <FSelect name="equipment" label="Equipamento" options={equipments.data?.map(i => ({ value: i.id, label: i.name }))}/>
            <FSelect name="fisio" label="Fisioterapeuta" options={fisios.data?.map(i => ({ value: i.id, label: i.name }))}/>
            <FSelect name="patient" label="Paciente" options={patients.data?.map(i => ({ value: i.id, label: i.name }))}/>

            <FDate name="retired_date" label="Data de Retirado"/>
            <FDate name="returned_date" label="Data de Devolução"/>
          </Form>
        </FormikProvider>}
      </Modal>
    </React.Fragment>
  )
}
