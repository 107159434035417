import {DeleteOutlined} from '@ant-design/icons'
import {Button, Divider, List, notification, Popconfirm} from 'antd'
import * as React from 'react'
import {useMutation, useQuery} from 'react-query'
import {Link, useHistory} from 'react-router-dom'
import {qs} from '../../queries'

export const EvolutionsTable: React.FC<{ item_id: number | string }> = ({ item_id }) => {
  const history = useHistory()

  const IS_TABLE_VIEW = history.location.pathname.indexOf('list') > -1
  const ROOT_PATH = IS_TABLE_VIEW ? '/attendances-list' : '/attendances'

  const { data, isFetching, refetch } = useQuery(['getEvolutions', item_id], () => qs.evolutions.all(`filter{attendance}=${item_id}`))
  const destroy = useMutation((values: any) => qs.evolutions.delete(values))
  const copy_interventions = useMutation(() => qs.attendances.copy_from_last(item_id), {
    onSuccess: (res) => {
      refetch()

      if (res.success) {
        notification.success({ message: 'Intervenções importadas!' })
      } else {
        notification.info({ message: 'Nenhuma intervenção importada!' })
      }
    }
  })

  return (
    <React.Fragment>
      <Button type="primary" style={{ marginBottom: '10px' }}>
        <Link to={`${ROOT_PATH}/${item_id}/evolutions/new`}>Nova Intervenção</Link>
      </Button>

      <Button
        type="dashed" style={{ marginBottom: '10px', marginLeft: '10px' }}
        onClick={() => copy_interventions.mutate()}
        loading={copy_interventions.isLoading}
      >
        Copiar intervenções do atendimento anterior
      </Button>

      <List
        loading={isFetching}
        itemLayout="horizontal"
        dataSource={data}
        rowKey="id"
        renderItem={item => (
          <List.Item
            actions={[
              <>
                <Link to={`${ROOT_PATH}/${item_id}/evolutions/${item.id}`}>Detalhes</Link>

                <Divider type="vertical"/>

                <Popconfirm
                  title="Tem certeza que gostaria de excluir esse item?"
                  onConfirm={() => destroy.mutate(item)}
                  okText="Sim, excluir!"
                  cancelText="Não"
                >
                  <Button size="small" danger shape="circle" icon={<DeleteOutlined/>}/>
                </Popconfirm>

              </>
            ]}
          >
            <List.Item.Meta
              title={item.intervention_name}
              description={<>
                Series: {item.series}
                <br/>
                Repetições: {item.repeats}
                <br/>
                Carga (Kg): {item.weight}
              </>}
            />
          </List.Item>
        )}
      />
    </React.Fragment>
  )
}
