import {ExclamationCircleOutlined} from '@ant-design/icons'
import {Layout, Modal, notification, Spin} from 'antd'

import Scheduler, {Resource} from 'devextreme-react/scheduler'
import 'devextreme/dist/css/dx.common.css'

import 'devextreme/dist/css/dx.light.css'
import {AppointmentClickEvent, AppointmentDblClickEvent, AppointmentUpdatingEvent} from 'devextreme/ui/scheduler'
import * as React from 'react'
import {useState} from 'react'

import {useMutation, useQuery} from 'react-query'
import {useMediaQuery} from 'react-responsive'
import {renderRoutes, RouteConfigComponentProps} from 'react-router-config'
import {useHistory} from 'react-router-dom'
import {CustomPageHeader} from '../../../components/layout/CustomPageHeader'
import {qs} from '../../queries'

const Color = require('color')

const views = ['day', 'week', 'month', 'agenda']
type Props = RouteConfigComponentProps

const AttCalendar = React.memo(() => {
  const history = useHistory()
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 })
  const [current_view, setCurrentVieew] = useState<any>('week')

  const { isFetching, data, refetch } = useQuery('getAttendancesCalendar', () => qs.attendances.calendar())
  const fisios = useQuery('getFisios', () => qs.fisios.all())

  const create = useMutation((values: any) => qs.attendances.post(values), {
    onSuccess: (values) => {
      refetch()
      history.push(`/attendances/${values.data.id}`)
    }
  })
  const update = useMutation((values: any) => qs.attendances.patch(values), {
    onSuccess: () => {
      refetch()
    }
  })

  const handleItemClick = (e: AppointmentDblClickEvent | AppointmentClickEvent) => {
    // @ts-ignore
    let attedance_id = e.appointmentData.meta_attedance_id

    if (attedance_id) {
      history.push(`/attendances/${attedance_id}`)
    } else {
      Modal.confirm({
        closable: true,
        maskClosable: true,
        icon: <ExclamationCircleOutlined/>,
        content: 'Atendimento não foi lançado ainda, gostaria de lançar agora?',
        onOk() {
          notification.info({ message: 'Processando!' })
          let target_data: any = e.targetedAppointmentData

          create.mutate({
            fisio: target_data.meta_fisio_id,
            patient: target_data.meta_patient_id,
            date_start: target_data.startDate,
            date_end: target_data.endDate,
            attendance_type: 'SESSION',
            status: 'PENDENT'
          })
        },
        onCancel() {

        }
      })
    }
  }

  const handleItemDrag = (e: AppointmentUpdatingEvent) => {
    let new_data = e.newData
    let attedance_id = new_data.meta_attedance_id

    if (attedance_id) {
      notification.info({ message: 'Processando!' })

      update.mutate({
        id: attedance_id,
        date_start: new_data.startDate,
        date_end: new_data.endDate
      })

    } else {
      Modal.confirm({
        closable: true,
        maskClosable: true,
        icon: <ExclamationCircleOutlined/>,
        content: 'Atendimento não foi lançado ainda, gostaria de lançar agora?',
        onOk() {
          notification.info({ message: 'Processando!' })

          create.mutate({
            fisio: new_data.meta_fisio_id,
            patient: new_data.meta_patient_id,
            date_start: new_data.startDate,
            original_date_start: new_data.meta_start_time,
            date_end: new_data.endDate,
            attendance_type: 'SESSION',
            status: 'PENDENT'
          })
        },
        onCancel() {

        }
      })
    }
  }

  const handleCalendarWidth = () => {
    if (isTabletOrMobile) {
      return current_view === 'agenda' || current_view === 'day' ? '' : '220vw'
    }

    return ''
  }

  const resourcesData = fisios.data?.map(i => {
    return {
      id: i.id,
      text: i.name,
      color: Color('#30859f').rotate(i.id * 35).hex()
    }
  })

  return (
    <Spin spinning={isFetching} size="large">
      <Scheduler
        style={{ zIndex: 0 }}
        timeZone="America/Sao_Paulo"
        dataSource={data}
        // @ts-ignore
        views={views}
        currentView={current_view}
        onCurrentViewChange={(curr) => setCurrentVieew(curr)}
        defaultCurrentView={current_view}
        useDropDownViewSwitcher
        height={isTabletOrMobile ? '92vh' : '88vh'}
        width={handleCalendarWidth()}
        startDayHour={6}
        endDayHour={20}
        editing={{
          allowAdding: false,
          allowDeleting: false,
          allowDragging: !isTabletOrMobile,
          allowUpdating: true
        }}
        onAppointmentFormOpening={(e) => {
          e.cancel = true
        }}
        onAppointmentClick={(e) => {
          console.log(e)
          e.cancel = true
          handleItemClick(e)
        }}
        onAppointmentDblClick={(e) => {
          console.log(e)
          e.cancel = true
          handleItemClick(e)
        }}
        recurrenceEditMode="series"
        onAppointmentUpdating={(e) => {
          console.log('onAppointmentUpdating')
          console.log(e)
          e.cancel = true
          handleItemDrag(e)
        }}
      >
        <Resource dataSource={resourcesData} fieldExpr="fisioGroupId" label=""/>
      </Scheduler>
    </Spin>
  )
})

export const AttendanceCalendarPage: React.FC<Props> = React.memo((props) => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 })

  return (
    <React.Fragment>
      <CustomPageHeader title={'Agenda - Atendimentos'}/>

      {isTabletOrMobile
        ?
        <div style={{ width: '100vw', height: '92vh', overflow: 'scroll' }}>
          <AttCalendar/>
        </div>
        :
        <Layout.Content className="body-container" style={{ padding: 0 }}>
          <AttCalendar/>
        </Layout.Content>
      }

      {renderRoutes(props.route?.routes)}
    </React.Fragment>
  )
})
