import {notification} from 'antd'
import {useAppStore} from '../components/hooks'
import {apiAxios, setLocalAuthToken} from '../services/api'
import queryClient from '../services/queryClient'
import {
  AttedanceEventType,
  AttendanceModel,
  EquipmentModel,
  EvolutionModel,
  InterventionModel,
  LoanModel,
  PatientModel,
  SlotModel,
  TransactionModel,
  UserModel
} from './typings'

type GetRequestResult<T> = {
  results: T
}

const apiResource = <Model>(domain: string, title: string, all_key?: string) => {
  return {
    all: async (query_string?: string) => {
      let path = `/${domain}`

      if (query_string) {
        path = `${domain}?${query_string}`
      }

      const { data } = await apiAxios.get<GetRequestResult<Model[]>>(path)
      return data.results
    },
    get: async (item_id: number | string) => {
      if (item_id === 'new') {
        return null
      }
      const { data } = await apiAxios.get<Model>(`/${domain}/${item_id}`)
      return data
    },
    post: async ({ form_data_keys, id, ...values }: any) => {
      try {
        let data = values

        if (form_data_keys) {
          for (let o of form_data_keys) {
            if (values[o]) {
              data = new FormData()
              Object.keys(values).forEach(k => {
                data.append(k, values[k])
              })
              break
            }
          }
        }

        let res = await apiAxios.post(`/${domain}`, data)
        notification.success({ message: `${title} Criado` })

        if (all_key) await queryClient.invalidateQueries(all_key)

        return res
      } catch (e) {
        notification.error({ message: 'Erro ao criar' })
        return Promise.reject(e)
      }
    },
    patch: async ({ id, form_data_keys, ...values }: any) => {
      try {
        let data = values

        if (form_data_keys) {
          for (let o of form_data_keys) {
            if (values[o]) {
              data = new FormData()
              Object.keys(values).forEach(k => {
                data.append(k, values[k])
              })
              break
            }
          }
        }

        let res = await apiAxios.patch(`/${domain}/${id}`, data)

        notification.success({ message: `${title} atualizado` })

        if (all_key) await queryClient.invalidateQueries(all_key)

        return res
      } catch (e) {
        notification.error({ message: 'Erro ao atualizar' })
        return Promise.reject(e)
      }
    },
    delete: async ({ id }: any) => {
      try {
        let res = await apiAxios.delete(`/${domain}/${id}`)

        notification.success({ message: `${title} excluído!` })

        if (all_key) await queryClient.invalidateQueries(all_key)

        return res
      } catch (e) {
        notification.error({ message: 'Erro ao excluir!' })
        return Promise.reject(e)
      }
    }
  }
}

const equipments = apiResource<EquipmentModel>('equipments', 'Equipamento', 'getEquipments')

const interventions = apiResource<InterventionModel>('interventions', 'Intervenção', 'getInterventions')

const patients = {
  ...apiResource<PatientModel>('patients', 'Paciente', 'getPatients'),
  with_evolutions: async (item_id: number | string) => {
    const { data } = await apiAxios.get<PatientModel>(`/patients/${item_id}?include[]=attendances.evolutions.intervention.id`)
    return data
  }
}

const fisios = apiResource<UserModel>('fisios', 'Fisioterapeuta', 'getFisios')

const setTokenStore = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      useAppStore.setState({ is_authenticated: true })
      resolve(true)
    }, 1000 * 2)
  })
}

const users = {
  ...apiResource<UserModel>('users', 'Usuários', 'getUsers'),
  getMe: async () => {
    const { data } = await apiAxios.get(`/users/me/`)
    return data
  },
  getAuthToken: async (values: any) => {
    try {
      let { data } = await apiAxios.post('/auth-token/', values)
      setLocalAuthToken(data.access)
      await setTokenStore()
      return data.token
    } catch (e) {
      notification.error({ message: 'Verifique as credenciais' })
      return Promise.reject(e)
    }
  },
  updateMe: async (values: any) => {
    try {

      let res = await apiAxios.patch(`/users/update-me`, values)

      notification.success({ message: `Perfil atualizado!` })

      await queryClient.invalidateQueries('getMe')

      return res
    } catch (e) {
      notification.error({ message: 'Erro ao atualizar' })
      return Promise.reject(e)
    }
  }

}
const slots = apiResource<SlotModel>('slots', 'Horário', 'getSlots')
const loans = apiResource<LoanModel>('loans', 'Empréstimo', 'getLoans')
const attendances = {
  ...apiResource<AttendanceModel>('attendances', 'Atendimento', 'getAttendances'),
  calendar: async () => {
    const { data } = await apiAxios.get<GetRequestResult<AttedanceEventType[]>>('attendances/calendar')
    return data.results
  },
  client: async () => {
    const { data } = await apiAxios.get<GetRequestResult<AttendanceModel[]>>('attendances/client')
    return data.results
  },
  client_details: async (att_id: string | number) => {
    const { data } = await apiAxios.get<AttendanceModel>('attendances/client?att_id=' + att_id)
    return data
  },
  copy_from_last: async (item_id: number | string) => {
    type Response = {
      success: boolean
    }

    const { data } = await apiAxios.get<Response>(`attendances/${item_id}/copy_from_last`)
    return data
  }
}
const evolutions = apiResource<EvolutionModel>('evolutions', 'Evolução', 'getEvolutions')
const transactions = {
  ...apiResource<TransactionModel>('transactions', 'Transação', 'getTransactions'),
  printRpa: async (item_id: number | string) => {
    const { data } = await apiAxios.get(`/transactions/${item_id}/print_rpa`)

    let winPrint = window.open()!
    winPrint.document.write(atob(data.html_data))
    winPrint.document.close()
    winPrint.focus()
    winPrint.print()

    return data
  },

  client: async () => {
    const { data } = await apiAxios.get<GetRequestResult<TransactionModel[]>>('transactions/client')
    return data.results
  },
  client_details: async (att_id: string | number) => {
    const { data } = await apiAxios.get<TransactionModel>('transactions/client?item_id=' + att_id)
    return data
  }
}

const attachments = {
  ...apiResource<any>('attachments', 'Anexos', 'getAttachments'),
  filtered: async (item_id: number | string | null | undefined, item_type) => {
    type Response = {
      results: {
        id: number,
        file_name: string,
        image: string,

      }[]
    }

    const { data } = await apiAxios.get<Response>(`attachments?filter{${item_type}_id}=${item_id}`)
    return data
  }
}

export const qs = {
  equipments,
  interventions,
  patients,
  fisios,
  users,
  slots,
  loans,
  attendances,
  evolutions,
  transactions,
  attachments
}

