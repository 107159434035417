import {QueryClient} from 'react-query'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      // refetchOnMount:false
    }
  }
})

export default queryClient