import {MenuOutlined, ZoomInOutlined} from '@ant-design/icons'
import {Layout, PageHeader, Table, Tag} from 'antd'
import {ColumnsType} from 'antd/lib/table/interface'
import * as React from 'react'
import {IMask} from 'react-imask'
import {useQuery} from 'react-query'
import {renderRoutes, RouteConfigComponentProps} from 'react-router-config'
import {Link} from 'react-router-dom'
import {useRecoilState} from 'recoil'
import {menuCollapseState} from '../../../state/atoms'
import {dateFormat} from '../../../utils/formatters'
import {qs} from '../../queries'
import {TransactionModel} from '../../typings'

type Props = RouteConfigComponentProps

const numberFormat = IMask.createPipe({
  mask: Number,
  scale: 2,
  thousandsSeparator: '.',
  radix: ',',
  normalizeZeros: true,
  padFractionalZeros: true
})

export const ClientTransactionListPage: React.FC<Props> = (props) => {
  const [collapsed, setCollapsed] = useRecoilState(menuCollapseState)

  const { isFetching, data, refetch } = useQuery('getTransactions', () => qs.transactions.client())

  const grid_columns: ColumnsType<TransactionModel> = [
    {
      title: 'Status',
      dataIndex: 'is_paid',
      width: 100,
      render: (value, row) => (
        row.is_paid ? <Tag color="green">Pago</Tag> : <Tag color="red">Pendente</Tag>
      )
    },
    {
      title: 'Vencimento',
      width: 100,
      dataIndex: 'due_date',
      render: (value) => dateFormat(value)
    },
    {
      title: 'Descrição',
      width: 400,
      dataIndex: 'description'
    },
    {
      title: 'Valor',
      dataIndex: 'amount',
      align: 'right',
      width: 100,
      render: (value, row) => (
        row.moving_type === 'INCOME' ?
          <b style={{ color: '#4caf50' }}>{numberFormat(String(value))}</b>
          :
          <b style={{ color: '#f13250' }}>{numberFormat(String(value))}</b>
      )
    },
    {
      title: 'Ações',
      dataIndex: 'id',
      align: 'right',
      width: 20,
      render: (value, row) => (
        <>
          <Link to={row.moving_type === 'INCOME' ? `/transactions/${value}` : `/transactions/expense/${value}`}>
            <ZoomInOutlined style={{ fontSize: '16px', marginRight: 5 }}/>
          </Link>
        </>
      )
    }
  ]

  return (
    <React.Fragment>
      <PageHeader
        ghost={false}
        title={'Financeiro'}
        onBack={() => setCollapsed(!collapsed)}
        backIcon={<MenuOutlined/>}
      />

      <Layout.Content className="body-container">
        <Table columns={grid_columns} dataSource={data} rowKey="id" loading={isFetching}/>
        {renderRoutes(props.route?.routes)}
      </Layout.Content>
    </React.Fragment>
  )
}
