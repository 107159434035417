import {ELASTIC_ENUM, KEEP_REST_ENUM, TIME_SELECT_ENUM, WIGHT_ENUM} from '../features/Attendance/pages/ClientAttendanceDetailPage'
import {dateTimeFormat} from './formatters'

interface Patient {
  id: number;
  fisio_name: string;
  responsible_name: string;
  responsible_username: string;
  attendances: Attendance[];
  name: string;
  cpf: null;
  birthday: null;
  email: string;
  phone: null;
  phone_emergency: null;
  address: null;
  city: null;
  state: null;
  cep: null;
  local: null;
  time: string;
  sun: boolean;
  mon: boolean;
  tue: boolean;
  wed: boolean;
  thu: boolean;
  fri: boolean;
  sat: boolean;
  contract_active: boolean;
  payment_type: null;
  closing_type: null;
  month_day: number;
  is_next_month: boolean;
  qtd_attendances: number;
  payment_deadline: null;
  price_attendance: null;
  contract: null;
  contractor_name: null;
  contractor_cpf: null;
  contractor_birthday: null;
  contractor_address: null;
  contractor_city: null;
  contractor_state: null;
  contractor_cep: null;
  created_at: string;
  updated_at: string;
  fisio: number;
  responsible: number;
  fisio_id: number;
  responsible_id: number;
}

interface Attendance {
  id: number;
  fisio_name: string;
  fisio_2_name?: string;
  patient_name: string;
  fisio: number;
  fisio_2: number | null;
  patient: number;
  evolutions: Evolution[];
  status: string;
  attendance_type: string;
  date_start: string;
  original_date_start: string;
  date_end: string;
  observations: null | string;
  blood_pressure_sys: number | null;
  blood_pressure_dias: number | null;
  heart_rate: number | null;
  fisio_sign: null;
  patient_sign: null;
  created_at: string;
  updated_at: string;
  fisio_id: number;
  fisio_2_id: number | null;
  patient_id: number;
}

interface Evolution {
  id: number;
  intervention_name: string;
  intervention: Intervention;
  attendance: number;
  frequency: number;
  series: number;
  repeats: number;
  time: number;
  keep_by: number;
  rest: number;
  weight: number;
  elastic: number;
  interval: number;
  observations: null | string;
  created_at: string;
  updated_at: string;
  intervention_id: number;
  attendance_id: number;
}

interface Intervention {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}

export const getPatientPrint = (item: Patient) => {
  const formatItems = () => {
    let _htm = ``

    for (let o of item.attendances) {
      let item_htm = `
      <br>
      <b>Data do Atendimento:</b> ${dateTimeFormat(o.date_start)}
      <br>
      <b>Fisioterapeuta:</b> ${o.fisio_name} ${o.fisio_2_name ? ' / ' + o.fisio_2_name : ''}
      
      <br>
      <br>
      
      <b>Pressão sistólica:</b> ${o.blood_pressure_sys ?? ''}</br>
      <b>Pressão diastólica:</b> ${o.blood_pressure_dias ?? ''}</br>
      <b>Frequência cardíaca (BPM):</b> ${o.heart_rate ?? ''}</br>
      <b>Observações:</b> ${o.observations ?? ''}</br>

      </p>
      
      <b>Intervenções:</b>
      <br>
      <br>
      `

      for (let e of o.evolutions) {
        item_htm += `
          ${e.intervention_name}
          <br>
  
          ${!!e.series ? `<span>Series: ${e.series}, <span/>` : ''}
  
          ${!!e.repeats ? `<span>Repetições: ${e.repeats}, <span/>` : ''}
  
          ${!!e.time ? `<span>Fazer por: ${TIME_SELECT_ENUM[e.time]}, <span/>` : ''}
  
          ${!!e.keep_by ? `<span>Manter por: ${KEEP_REST_ENUM[e.keep_by]}, <span/>` : ''}
  
          ${!!e.rest ? `<span>Descanso: ${KEEP_REST_ENUM[e.rest]}, <span/>` : ''}
  
          ${!!e.weight ? `<span>Carga (Kg): ${WIGHT_ENUM[e.weight]}, <span/>` : ''}
  
          ${!!e.elastic ? `<span>Elástico: ${ELASTIC_ENUM[e.elastic]}, <span/>` : ''}
  
          ${!!e.observations ? `<span>Observações: ${e.observations}, <span/>` : ''}
          
          <br>
          <br>
        `
      }

      _htm += item_htm
      _htm += '<br><hr>'
    }

    return _htm
  }

  const html = `
<!DOCTYPE html>
<html lang="pt">
<head>
    <meta charset="UTF-8">
    <style type="text/css">
        body {
            font-family: "Helvetica Neue", "Segoe UI", helvetica, verdana, sans-serif
        }
    </style>
</head>
<body>

<center>
    <img src="https://firebasestorage.googleapis.com/v0/b/revita-app-98582.appspot.com/o/logo-revita.png?alt=media&token=df2a377e-a8d6-4c9f-95d8-19224d60857a"
         alt="" width="80px">
    <br>
    <br>
    <h2>Ficha de Evoluções</h2>
</center>

<p><b>Paciente</b> ${item.name}</p>
<br>

${formatItems()}

</body>
</html>
  `
  return html
}