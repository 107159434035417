import {Button, Form, Modal, notification, Tabs} from 'antd'
import {FormikProvider, useFormik} from 'formik'
import * as React from 'react'
import {useEffect, useState} from 'react'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {RouteConfigComponentProps} from 'react-router-config'
import {useHistory, useParams} from 'react-router-dom'
import * as Yup from 'yup'
import {FDate, FInput, FMoney, FormGroup, FSelect, FSwitch} from '../../../components/form/FormInputs'
import {apiAxios} from '../../../services/api'
import UploaderAttachment from '../../Attendance/components/UploaderAttachment'
import {qs} from '../../queries'
import {TransactionModel} from '../../typings'

type TransactionDetailPageProps = RouteConfigComponentProps

type FormValues = { [K in keyof TransactionModel]?: TransactionModel[K] | '' }

const PAY_SELECT = [
  { value: 'BOLETO', label: 'Boleto' },
  { value: 'WIRE', label: 'Transferência' },
  { value: 'CC', label: 'Cartão de crédito' },
  { value: 'CHECK', label: 'Cheque' },
  { value: 'MONEY', label: 'Dinheiro' }
]

export const ExpenseDetailPage: React.FC<TransactionDetailPageProps> = (props) => {
  const { item_id } = useParams<{ item_id: string }>()
  const history = useHistory()
  const queryClient = useQueryClient()

  const cur_item = useQuery(['getTransaction', item_id], () => qs.transactions.get(item_id), {
    enabled: false,
    retry: false
  })
  const fisios = useQuery('getFisios', () => qs.fisios.all())
  const print_rpa = useQuery('printRpa', () => qs.transactions.printRpa(item_id), {
    enabled: false,
    retry: false
  })

  const create = useMutation(values => qs.transactions.post(values), {
    onSuccess: () => {
      handleCancel()
    }
  })
  const update = useMutation(values => qs.transactions.patch(values))

  const [calculateLoading, setCalculateLoading] = useState(false)
  const [calculation, setCalculation] = useState<any>(null)

  useEffect(() => {
    cur_item.refetch()
  }, [item_id])

  let mutating = create.isLoading || update.isLoading

  const formikBag = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      id: cur_item.data?.id ?? '',
      amount_service: cur_item.data?.amount_service ?? '',
      amount: cur_item.data?.amount ?? '',
      date: cur_item.data?.date ?? '',
      due_date: cur_item.data?.due_date ?? '',
      is_paid: cur_item.data?.is_paid ?? false,
      description: cur_item.data?.description ?? '',
      payment_type: cur_item.data?.payment_type ?? '',
      fisio: cur_item.data?.fisio ?? '',
      moving_type: 'EXPENSE'
    },
    validationSchema: Yup.object().shape({
      amount: Yup.string().required(),
      amount_service: Yup.string().required(),
      date: Yup.string().required(),
      due_date: Yup.string().required(),
      payment_type: Yup.string().required()
    }),
    onSubmit: (values: any) => {
      if (cur_item.data?.id) {
        update.mutate(values)
      } else {
        create.mutate(values)
      }
    }
  })

  const handleCancel = () => {
    queryClient.removeQueries(['getTransaction', item_id], { inactive: true, stale: true })

    setTimeout(() => {
      history.push('/transactions')
    }, 200)
  }

  const handleCalculate = async () => {
    setCalculateLoading(true)
    let amount_service = formikBag.values.amount_service

    if (amount_service) {
      let { data } = await apiAxios.post('/transactions/calculate_rpa', { value: amount_service })
      await formikBag.setFieldValue('amount', data['total'])
      setCalculation(data)
    } else {
      notification.error({ message: 'Informe o valor do serviços prestados!' })
    }

    setCalculateLoading(false)
  }

  return (
    <React.Fragment>
      <Modal
        title={cur_item?.data?.id ? 'Editar despesa' : 'Nova despesa'}
        visible={true}
        maskClosable
        destroyOnClose
        onCancel={handleCancel}
        width={550}
        className="ant-modal-tabs-only"
        closable={false}
        footer={[
          <Button key="3" type="default" onClick={handleCalculate} loading={calculateLoading}>Calcular valor líquido</Button>,
          cur_item?.data?.id &&
          <Button key="4" type="default" onClick={() => print_rpa.refetch()} loading={print_rpa.isLoading}>Imprimir RPA</Button>,
          <Button key="2" type="default" onClick={handleCancel}>Fechar</Button>,
          <Button key="1" type="primary" onClick={formikBag.submitForm} loading={mutating}>Salvar</Button>
        ]}
      >
        {!cur_item.isLoading && !fisios.isLoading &&
          <FormikProvider value={formikBag}>
            <Tabs defaultActiveKey={'1'}>
              <Tabs.TabPane tab="Detalhes" key="1">
                <Form layout="vertical">
                  <FormGroup>
                    <FMoney name="amount_service" label="Valor serviços prestados"/>
                    <FMoney name="amount" label="Valor líquido"/>
                  </FormGroup>

                  <FormGroup>
                    <FDate name="date" label="Data"/>
                    <FDate name="due_date" label="Vencimento"/>
                    <FSwitch name="is_paid" label="Foi pago?"/>
                  </FormGroup>

                  <FormGroup>
                    <FInput name="description" label="Descrição"/>
                    <FSelect name="payment_type" label="Forma de Pagamento" options={PAY_SELECT}/>
                  </FormGroup>

                  <FSelect name="fisio" label="Fisioterapeuta" options={fisios.data?.map(i => ({ value: i.id, label: i.name }))}/>
                </Form>
              </Tabs.TabPane>

              <Tabs.TabPane tab="Anexos" key="3">
                <UploaderAttachment object_id={cur_item.data?.id} object_type="transaction"/>
              </Tabs.TabPane>
            </Tabs>
          </FormikProvider>}

        {calculation?.total &&
          <React.Fragment>
            <table width="100%">
              <thead>
              <tr>
                <th>IRPF Base</th>
                <th>IRPF Retido</th>
                <th>ISS Retido</th>
                <th>INSS Retido</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>{calculation.irpf_base}</td>
                <td>{calculation.irpf} ({calculation.ir_aliq}%)</td>
                <td>{calculation.iss} ({calculation.iss_aliq}%)</td>
                <td>{calculation.inss} ({calculation.inss_aliq}%)</td>
              </tr>
              </tbody>
            </table>
          </React.Fragment>}
      </Modal>
    </React.Fragment>
  )
}
