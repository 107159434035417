import {MenuOutlined} from '@ant-design/icons'
import {Button, Col, Form, Layout, PageHeader, Row, Tabs} from 'antd'
import {FormikProvider, useFormik} from 'formik'
import * as React from 'react'
import {useMutation, useQuery} from 'react-query'
import {useRecoilState} from 'recoil'
import * as Yup from 'yup'
import {FInput, FormGroup} from '../../../components/form/FormInputs'
import {menuCollapseState} from '../../../state/atoms'
import {TimeSheetTab} from '../../Physio/components/TimeSheetTab'
import {qs} from '../../queries'
import {UserModel} from '../../typings'

type FormValues = { [K in keyof UserModel]?: UserModel[K] | '' } & { new_password: string }

export const ProfilePage: React.FC = (props) => {
  let title = 'Perfil'

  const { isLoading, data } = useQuery('getMe', () => qs.users.getMe())
  const update = useMutation(values => qs.users.updateMe(values))
  const [collapsed, setCollapsed] = useRecoilState(menuCollapseState)

  const formikBag = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      id: data?.id ?? '',
      name: data?.name ?? '',
      crefito: data?.crefito ?? '',
      cpf: data?.cpf ?? '',
      email: data?.email ?? '',
      phone: data?.phone ?? '',
      phone_emergency: data?.phone_emergency ?? '',
      address: data?.address ?? '',
      city: data?.city ?? '',
      state: data?.state ?? '',
      cep: data?.cep ?? '',
      bank: data?.bank ?? '',
      bank_agency: data?.bank_agency ?? '',
      bank_account: data?.bank_account ?? '',
      pis: data?.pis ?? '',
      new_password: ''
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(),
      email: Yup.string().email().required()
    }),
    onSubmit: (values: any) => {
      if (data?.id) {
        update.mutate(values)
      }
    }
  })

  return (
    <React.Fragment>
      <PageHeader
        ghost={false}
        title={title}
        onBack={() => setCollapsed(!collapsed)}
        backIcon={<MenuOutlined/>}
      />

      <Layout.Content className="body-container">

        {!isLoading &&
        <FormikProvider value={formikBag}>
          <Form layout="vertical">
            <Tabs>
              <Tabs.TabPane tab="Dados Pessoais" key="1">
                <Row gutter={48}>
                  <Col span={24}>
                    <FormGroup>
                      <FInput name="name" label="Nome"/>
                      <FInput name="cpf" label="CPF"/>
                      <FInput name="crefito" label="CREFITO 11"/>
                    </FormGroup>
                    <FormGroup>
                      <FInput name="email" label="E-mail"/>
                      <FInput name="phone" label="Telefone Próprio"/>
                      <FInput name="phone_emergency" label="Telefone Emergência/Recado"/>
                    </FormGroup>

                    <FInput name="address" label="Endereço"/>
                    <FormGroup>
                      <FInput name="city" label="Cidade"/>
                      <FInput name="state" label="Estado"/>
                      <FInput name="cep" label="CEP"/>
                    </FormGroup>
                  </Col>
                </Row>
              </Tabs.TabPane>

              <Tabs.TabPane tab="Dados Bancários" key="2">
                <FormGroup>
                  <FInput name="bank" label="Banco"/>
                  <FInput name="bank_agency" label="Agência"/>
                  <FInput name="bank_account" label="Conta"/>
                </FormGroup>

                <FormGroup>
                  <FInput name="pis" label="NIT/PIS"/>
                </FormGroup>
              </Tabs.TabPane>

              <Tabs.TabPane tab="Agenda" key="3">
                {data?.id && <TimeSheetTab fisio_id={data.id}/>}
              </Tabs.TabPane>

              <Tabs.TabPane tab="Acesso" key="4">
                <b>Usuário: {data?.username}</b> <br/><br/>
                <FormGroup>
                  <FInput name="new_password" label="Nova Senha" type="password"/>
                </FormGroup>
              </Tabs.TabPane>
            </Tabs>

            <Button key="1" type="primary" onClick={formikBag.submitForm} loading={update.isLoading}>Salvar</Button>
          </Form>
        </FormikProvider>}
      </Layout.Content>
    </React.Fragment>
  )
}
