import {
  BarChartOutlined,
  CalculatorOutlined,
  CalendarOutlined,
  ContactsOutlined,
  ExperimentOutlined,
  LogoutOutlined,
  ScheduleOutlined,
  SettingOutlined,
  SwapOutlined,
  TeamOutlined,
  ToolOutlined,
  UserOutlined
} from '@ant-design/icons'
import {Drawer, Layout, Menu} from 'antd'
import * as React from 'react'
import {useQuery} from 'react-query'
import {useMediaQuery} from 'react-responsive'
import {Link, useLocation} from 'react-router-dom'
import {useRecoilState} from 'recoil'
import logo_icon from '../../assets/logo-revita-icon.png'
import logo from '../../assets/logo-revita.png'
import {qs} from '../../features/queries'
import {menuCollapseState} from '../../state/atoms'
import InfoVersion from './InfoVersion'

type Props = {}

const { Sider } = Layout

export const Sidebar: React.FC<Props> = (props) => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 })
  const { pathname } = useLocation()
  const { data } = useQuery('getMe', () => qs.users.getMe())
  const [collapsed, setCollapsed] = useRecoilState(menuCollapseState)

  let is_admin = data?.role === 'ADMIN'
  let is_admin_or_assistant = data?.role === 'ADMIN' || data?.role === 'ASSISTANT'

  const AppMenu = () => (
    <React.Fragment>
      <Menu theme="light" mode="inline" defaultSelectedKeys={['/']} selectedKeys={[pathname]}>
        <Menu.Item key="/attendances" icon={<CalendarOutlined/>}>
          <Link to={'/attendances'}>Atendimentos</Link>
        </Menu.Item>

        <Menu.Item key="/attendances-list" icon={<ScheduleOutlined/>}>
          <Link to={'/attendances-list'}>Atendimentos Tabela</Link>
        </Menu.Item>

        <Menu.Item key="/patients" icon={<ContactsOutlined/>}>
          <Link to={'/patients'}>Pacientes</Link>
        </Menu.Item>

        {is_admin_or_assistant &&
          <Menu.Item key="/fisios" icon={<TeamOutlined/>}>
            <Link to={'/fisios'}>Equipe</Link>
          </Menu.Item>}

        <Menu.Item key="/interventions" icon={<ExperimentOutlined/>}>
          <Link to={'/interventions'}>Intervenções</Link>
        </Menu.Item>

        <Menu.Item key="/loans" icon={<SwapOutlined/>}>
          <Link to={'/loans'}>Empréstimos</Link>
        </Menu.Item>

        <Menu.Item key="/equipments" icon={<ToolOutlined/>}>
          <Link to={'/equipments'}>Equipamentos</Link>
        </Menu.Item>

        {is_admin_or_assistant &&
          <Menu.Item key="/transactions" icon={<CalculatorOutlined/>}>
            <Link to={'/transactions'}>Financeiro</Link>
          </Menu.Item>}

        {is_admin &&
          <Menu.Item key="reports" icon={<BarChartOutlined/>}>
            <Link to={'/child/5'}>Relatórios</Link>
          </Menu.Item>}

        {is_admin &&
          <Menu.Item key="/configs" icon={<SettingOutlined/>}>
            <Link to={'/configs'}>Configurações</Link>
          </Menu.Item>}

        <Menu.Item key="/profile" icon={<UserOutlined/>}>
          <Link to={'/profile'}>
            <span style={{ color: '#999', marginRight: 4 }}>Olá, </span>
            <span>{data?.name}</span>
          </Link>
        </Menu.Item>

        <Menu.Item key="SignOut" icon={<LogoutOutlined/>}>
          <Link to={`/login`}>Sair</Link>
        </Menu.Item>
      </Menu>

      <InfoVersion/>
    </React.Fragment>
  )

  return (
    <React.Fragment>
      <Drawer
        placement="left"
        visible={collapsed}
        onClose={(collapsed) => { setCollapsed(!collapsed) }}
        closeIcon={false}
        bodyStyle={{
          padding: 0
        }}
      >
        <img className="logo" src={logo}/>
        <AppMenu/>
      </Drawer>

      {!isTabletOrMobile &&
        <Sider
          theme="light"
          defaultCollapsed={true}
          collapsed={true}
          trigger={null}
          collapsedWidth={60}
        >
          {!isTabletOrMobile && <img className="logo-icon" src={logo_icon}/>}
          <AppMenu/>
        </Sider>}
    </React.Fragment>
  )
}

export const ClientSidebar: React.FC<Props> = (props) => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 })
  const { pathname } = useLocation()
  const [collapsed, setCollapsed] = useRecoilState(menuCollapseState)

  const AppMenu = () => (
    <React.Fragment>
      <Menu theme="light" mode="inline" defaultSelectedKeys={['/']} selectedKeys={[pathname]}>
        <Menu.Item key="/attendances" icon={<CalendarOutlined/>}>
          <Link to={'/attendances'}>Atendimentos</Link>
        </Menu.Item>

        <Menu.Item key="/transactions" icon={<CalculatorOutlined/>}>
          <Link to={'/transactions'}>Financeiro</Link>
        </Menu.Item>

        <Menu.Item key="SignOut" icon={<LogoutOutlined/>}>
          <Link to={`/login`}>Sair</Link>
        </Menu.Item>
      </Menu>

      <InfoVersion/>
    </React.Fragment>
  )

  return (
    <React.Fragment>
      <Drawer
        placement="left"
        visible={collapsed}
        onClose={(collapsed) => { setCollapsed(!collapsed) }}
        closeIcon={false}
        bodyStyle={{
          padding: 0
        }}
      >
        <img className="logo" src={logo}/>
        <AppMenu/>
      </Drawer>

      {!isTabletOrMobile &&
        <Sider
          theme="light"
          defaultCollapsed={true}
          collapsed={true}
          trigger={null}
          collapsedWidth={60}
        >
          {!isTabletOrMobile && <img className="logo-icon" src={logo_icon}/>}
          <AppMenu/>
        </Sider>}
    </React.Fragment>
  )
}
