import {MenuOutlined, ZoomInOutlined} from '@ant-design/icons'
import {Button, Col, Form, Input, Layout, PageHeader, Row, Table} from 'antd'
import {ColumnsType} from 'antd/lib/table/interface'
import * as React from 'react'
import {useState} from 'react'
import {useQuery} from 'react-query'
import {renderRoutes, RouteConfigComponentProps} from 'react-router-config'
import {Link, useHistory} from 'react-router-dom'
import {useRecoilState} from 'recoil'
import {useMeQuery} from '../../../components/hooks'
import {menuCollapseState} from '../../../state/atoms'
import {dateFormat} from '../../../utils/formatters'
import {qs} from '../../queries'
import {LoanModel} from '../../typings'

const queryString = require('query-string')

const AdvancedSearchForm = React.memo(({ setQsQuery, refetch }: { setQsQuery: (data: any) => void, refetch: () => void }) => {
  const [filterForm] = Form.useForm()

  const onFinish = (values: any) => {
    let __qs = queryString.stringify(values, { arrayFormat: 'comma' })

    setQsQuery(__qs)
    setQsQuery(__qs)

    refetch()
  }

  return (
    <Form
      form={filterForm}
      name="advanced_search"
      className="advanced-search-form"
      onFinish={onFinish}
      layout={'vertical'}
    >
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item name={`filter{equipment.name.icontains}`} label={`Equipamento`}>
            <Input/>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name={`filter{fisio.name.icontains}`} label={`Fisioterapeuta`}>
            <Input/>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name={`filter{patient.name.icontains}`} label={`Paciente`}>
            <Input/>
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button type="primary" htmlType="submit">
            Filtrar
          </Button>
          <Button
            style={{ margin: '0 8px' }}
            onClick={() => {
              filterForm.resetFields()
              setQsQuery('')
              setQsQuery('')
              refetch()
            }}
          >
            Resetar filtro
          </Button>
        </Col>
      </Row>
    </Form>
  )
})

type Props = RouteConfigComponentProps

export const LoanListPage: React.FC<Props> = (props) => {
  const [qs_query, setQsQuery] = useState('')
  const { isFetching, data, refetch } = useQuery('getLoans', () => qs.loans.all(qs_query))
  const history = useHistory()
  const [collapsed, setCollapsed] = useRecoilState(menuCollapseState)

  const grid_columns: ColumnsType<LoanModel> = [
    {
      title: 'Equipamento',
      width: 300,
      dataIndex: 'equipment_name'
    },
    {
      title: 'Fisioterapeuta',
      width: 150,
      dataIndex: 'fisio_name'
    },
    {
      title: 'Paciente',
      width: 150,
      dataIndex: 'patient_name'
    },
    {
      title: 'Data de Retirado',
      dataIndex: 'retired_date',
      width: 80,
      render: (value) => dateFormat(value)
    },
    {
      title: 'Data de Devolução',
      dataIndex: 'returned_date',
      width: 80,
      render: (value) => dateFormat(value)
    },
    {
      title: 'Ações',
      dataIndex: 'id',
      align: 'right',
      width: 20,
      render: (value) => (
        <Link to={`/loans/${value}`}><ZoomInOutlined style={{ fontSize: '16px', marginRight: 5 }}/></Link>
      )
    }
  ]

  const { is_admin_or_assistant } = useMeQuery()

  return (
    <React.Fragment>
      <PageHeader
        ghost={false}
        title={'Empréstimos'}
        onBack={() => setCollapsed(!collapsed)}
        backIcon={<MenuOutlined/>}
      />

      <AdvancedSearchForm refetch={refetch} setQsQuery={setQsQuery}/>

      <Layout.Content className="body-container">
        {is_admin_or_assistant && <Button type="primary" onClick={() => history.push('/loans/new')}>Novo</Button>}

        <Table columns={grid_columns} dataSource={data} rowKey="id" loading={isFetching}/>
        {renderRoutes(props.route?.routes)}
      </Layout.Content>
    </React.Fragment>
  )
}
