import * as React from 'react'
import {useEffect} from 'react'
import {Button, Form, Modal} from 'antd'
import {useHistory, useParams} from 'react-router-dom'
import {RouteConfigComponentProps} from 'react-router-config'
import {FormikProvider, useFormik} from 'formik'
import {FInput} from '../../../components/form/FormInputs'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {qs} from '../../queries'
import {InterventionModel} from '../../typings'
import * as Yup from 'yup'

type InterventionDetailPageProps = RouteConfigComponentProps

type FormValues = { [K in keyof InterventionModel]?: InterventionModel[K] | '' }

export const InterventionDetailPage: React.FC<InterventionDetailPageProps> = (props) => {
  const { item_id } = useParams<{ item_id: string }>()
  const history = useHistory()
  const queryClient = useQueryClient()

  const cur_item = useQuery(['getIntervention', item_id], () => qs.interventions.get(item_id), {
    enabled: false,
    retry: false
  })

  const create = useMutation(values => qs.interventions.post(values), {
    onSuccess: () => handleCancel()
  })
  const update = useMutation(values => qs.interventions.patch(values))

  useEffect(() => {
    cur_item.refetch()
  }, [item_id])

  let mutating = create.isLoading || update.isLoading

  const handleCancel = () => {
    queryClient.removeQueries(['getIntervention', item_id], { inactive: true, stale: true })

    setTimeout(() => {
      history.push('/interventions')
    }, 200)
  }

  const formikBag = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      id: cur_item.data?.id ?? '',
      name: cur_item.data?.name ?? ''
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required()
    }),
    onSubmit: (values: any) => {
      if (cur_item.data?.id) {
        update.mutate(values)
      } else {
        create.mutate(values)
      }
    }
  })

  return (
    <React.Fragment>
      <Modal
        visible={true}
        maskClosable
        destroyOnClose
        onCancel={handleCancel}
        width={400}
        closable={false}
        footer={[
          <Button key="2" type="default" onClick={handleCancel}>Fechar</Button>,
          <Button key="1" type="primary" onClick={formikBag.submitForm} loading={mutating}>Salvar</Button>
        ]}
      >
        {!cur_item.isLoading &&
        <FormikProvider value={formikBag}>
          <Form layout="vertical">
            <FInput name="name" label="Nome"/>
          </Form>
        </FormikProvider>}
      </Modal>
    </React.Fragment>
  )
}
